<template>
  <div>
    <section class="section">
      <div class="container">
        <div>
          <div class="columns">
            <div class="column">
              <p class="title is-5 has-text-info">
                <span class="icon">
                  <i class="fal fa-cookie-bite" />
                </span>
                <span>Batch upload summary</span>
              </p>
            </div>
            <div class="column is-narrow">
              <span class="buttons">
                <span
                  class="button is-small is-info is-light is-rounded"
                  @click="goBack"
                >
                  <span class="icon">
                    <i class="fas fa-arrow-left" />
                  </span>
                  <span>Go back</span>
                </span>
                <span
                  :disabled="$wait.is('summary-data-loading')"
                  class="button is-small is-soft is-rounded"
                  @click="reProcessSheet"
                >
                  <span class="icon">
                    <i class="fad fa-repeat"></i>
                  </span>
                  <span>Re-process sheet</span>
                </span>
              </span>
            </div>
          </div>
          <hr class="hr is-light" />
        </div>
        <SheetInfo v-if="item" />
      </div>
    </section>
    <section class="section">
      <div v-if="$wait.is('summary-data-loading')">
        <div class="hero-body">
          <div class="container">
            <Spinner />
          </div>
        </div>
      </div>
      <div class="container is-fluid" v-else>
        <SummaryTable />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'MultiSummary',
  components: {
    Spinner: () => import('core/components/Spinner'),
    SummaryTable: () => import('../components/MultiSummaryTable'),
    SheetInfo: () => import('../components/MultiSummarySheetInfo')
  },
  async mounted() {
    if (!this.item) return this.$router.push('/multi')

    try {
      this.$wait.start('summary-data-loading')
      await this.createSummary({
        filename: this.item.filename,
        token: this.token
      })

      window.onscroll = () => {
        const { innerHeight, scrollY } = window
        const fetch = innerHeight + scrollY >= document.body.offsetHeight
        if (fetch) this.loadMore()
      }
    } catch {
      this.$notify('Unable to load sheet data')
      this.$router.push({ name: 'multi-dash' }).catch(() => {})
    } finally {
      this.$wait.end('summary-data-loading')
    }
  },
  computed: {
    ...mapGetters({
      item: 'multi/summaryItem',
      token: 'auth/token'
    })
  },
  methods: {
    ...mapActions('multi/summary', ['createSummary', 'loadMore']),
    goBack() {
      this.$router.push({ name: 'multi-dash' }).catch(() => {})
    },
    async reProcessSheet() {
      this.$modal.open('multi/MultiReProcess', this.item.filename)
    }
  }
}
</script>
